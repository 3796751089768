import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Card, FormInput, RadioGroup, TextArea } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';
import { EditHealthConfigFormProps } from '@/hooks/forms/useHealthConfigForm';

import messages from '../messages';
import { Wrapper, ConfigWrapper } from './styles';

interface Props {
	formProps: EditHealthConfigFormProps;
	onFocus?: () => void;
}

export default function LandingPageCustomization({ formProps, onFocus }: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const { formatMessage } = useIntl();
	const radioOptions = [
		{
			value: 'true',
			id: 'yes',
			label: 'Yes',
		},
		{
			value: 'false',
			id: 'no',
			label: 'No',
		},
	];
	const impliedRadioOptions = [
		{
			value: 'true',
			id: 'yes',
			label: 'Enable Implied Eligibility',
		},
		{
			value: 'false',
			id: 'no',
			label: 'Disable Implied Eligibility',
		},
	];

	const landingHeaderInputProps = formProps.bindWithErrorProps('landingHeader', 'text');
	const landingBodyInputProps = formProps.bindWithErrorProps('landingBody', 'text');
	const baaInputProps = formProps.bindWithErrorProps('baaDataRetention', 'text');
	const enableDataFeedInputProps = formProps.bindWithErrorProps('enableDataFeedOverride', 'text');
	const impliedEligibilityInputProps = formProps.bindWithErrorProps('impliedSSOEligibilityEnabled', 'text');

	return (
		<section onFocus={onFocus} onClick={onFocus}>
			<Wrapper>
				<ConfigWrapper>
					<Card>
						<CellTitle>Landing Page Customization</CellTitle>
						<Subtitle noBottomMargin>{formatMessage(messages.landingPageCustomizationSubtitle)}</Subtitle>
						<br />
						{hasValidPermissions('landing_header', [actions.READ]) && (
							<FormInput
								{...landingHeaderInputProps}
								disabled={!hasValidPermissions('landing_header', [actions.UPDATE])}
								label="Header (Limit 50 characters)"
							/>
						)}
						{hasValidPermissions('landing_body', [actions.READ]) && (
							<TextArea
								{...landingBodyInputProps}
								disabled={!hasValidPermissions('landing_body', [actions.UPDATE])}
								label="Body (Limit 250 characters)"
							/>
						)}

						{hasValidPermissions('baa_data_retention', [actions.READ]) && (
							<>
								<RadioGroup
									{...baaInputProps}
									label="Should Calm retain data due to BAA?"
									disabled={!hasValidPermissions('baa_data_retention', [actions.UPDATE])}
									options={radioOptions}
									asCards
									aria-label="Should Calm retain data due to BAA?"
									display="flex"
									noMarginBottom={!hasValidPermissions('enable_data_feed_override', [actions.READ])}
								/>
							</>
						)}
						{hasValidPermissions('enable_data_feed_override', [actions.READ]) && (
							<>
								<RadioGroup
									{...enableDataFeedInputProps}
									label="Enable Data Feeds without BAA?"
									disabled={!hasValidPermissions('enable_data_feed_override', [actions.UPDATE])}
									options={radioOptions}
									asCards
									aria-label="Enable Data Feeds without BAA?"
									display="flex"
									noMarginBottom={!hasValidPermissions('implied_sso_eligibility_enabled', [actions.READ])}
								/>
							</>
						)}
						{hasValidPermissions('implied_sso_eligibility_enabled', [actions.READ]) && (
							<>
								<RadioGroup
									{...impliedEligibilityInputProps}
									label="Should Eligibility be implied during SSO?"
									disabled={!hasValidPermissions('implied_sso_eligibility_enabled', [actions.UPDATE])}
									options={impliedRadioOptions}
									asCards
									aria-label="Should Eligibility be implied during SSO?"
									display="flex"
									noMarginBottom
								/>
							</>
						)}
					</Card>
				</ConfigWrapper>
			</Wrapper>
		</section>
	);
}
