import { ReactElement } from 'react';

import { Card, FormInput, FormSelect, IconButton, RadioGroup, Text } from '@calm-web/design-system';
import { CircleMinus } from '@calm-web/icons';

import { PrimaryButton } from '@/components/pages/Reporting/SegmentationFilter/styles';
import CellTitle from '@/components/ui/CellTitle';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePermissions } from '@/hooks/auth';
import {
	EditHealthAssignmentOperatorFormProps,
	EditHealthAssignmentRuleAttributeFormProps,
} from '@/hooks/forms/useHealthAssignmentRuleForm';
import { AssignmentRuleOperator } from '@/types/health';
import { ALLOWED_COUNTRIES_LIST } from '@/utils/allowedCountries';

import { CellSubtitle } from '../styles';
import { OperatorText, RuleLogicalOperatorWrapper, RuleWrapper } from './styles';

const INTERNATIONAL_CONFIGS_FEATURE_FLAG = 'b2b-ch-international-configs';

function AssignmentRuleAttributeForm({
	formProps,
	isLast,
	hasMultiple,
	operator,
	onClickRemove,
	selectOptions,
	isDependentGroup,
}: {
	formProps: EditHealthAssignmentRuleAttributeFormProps;
	isLast: boolean;
	hasMultiple: boolean;
	operator: AssignmentRuleOperator;
	onClickRemove: () => void;
	selectOptions: { value: string; label: string }[];
	isDependentGroup?: boolean;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const isEdit = hasValidPermissions('health_sponsorship_assignment_rules', [actions.UPDATE]);
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(INTERNATIONAL_CONFIGS_FEATURE_FLAG);

	const isInternationConfigFlagTrue = flagValues?.[INTERNATIONAL_CONFIGS_FEATURE_FLAG] === true;

	const countryOptions =
		!flagLoading && !flagError && isInternationConfigFlagTrue
			? ALLOWED_COUNTRIES_LIST
			: [{ value: 'USA', label: 'USA' }];

	return (
		<RuleWrapper>
			<FormSelect
				aria-label="Rule Type"
				options={selectOptions}
				{...formProps.bindWithErrorProps('type', 'select')}
				data-testid="rule-type-select"
				disabled={!isEdit}
			/>
			<Text>is</Text>
			{isDependentGroup ? (
				<FormSelect
					aria-label="Value"
					options={countryOptions}
					{...formProps.bindWithErrorProps('value', 'select')}
				/>
			) : (
				<FormInput label="Value" {...formProps.bindWithErrorProps('value', 'text')} disabled={!isEdit} />
			)}
			{hasMultiple && (
				<>
					{isLast ? null : (
						<OperatorText data-testid="operator-text">{operator === 'and' ? 'AND' : 'OR'}</OperatorText>
					)}
					{isEdit && (
						<IconButton
							onPress={onClickRemove}
							Icon={CircleMinus}
							color="error"
							aria-label="Remove Rule Attribute"
						/>
					)}
				</>
			)}
		</RuleWrapper>
	);
}

const SegmentPartnerSelectOptions = [
	{ value: 'city', label: 'City' },
	{ value: 'clientId', label: 'Client ID' },
	{ value: 'clientIndustryCode', label: 'Client Industry Code' },
	{ value: 'country', label: 'Country' },
	{ value: 'gender', label: 'Gender' },
	{ value: 'occupationCode', label: 'Occupation Code' },
	{ value: 'payerName', label: 'Payer Name' },
	{ value: 'segment 1', label: 'Segment 1' },
	{ value: 'segment 2', label: 'Segment 2' },
	{ value: 'segment 3', label: 'Segment 3' },
	{ value: 'segment 4', label: 'Segment 4' },
	{ value: 'segment 5', label: 'Segment 5' },
	{ value: 'segment', label: 'Segment' },
	{ value: 'state', label: 'State' },
	{ value: 'zip', label: 'Zip' },
];

function AssignmentRuleForm({
	isSponsorCodePartner,
	isDependentGroup = false,
	assignmentRuleOperatorFormProps,
	assignmentRuleAttributeFormProps,
	addAssignmentRuleAttribute,
	removeAssignmentRuleAttribute,
	onFocus,
}: {
	isSponsorCodePartner: boolean;
	isDependentGroup?: boolean;
	assignmentRuleOperatorFormProps: EditHealthAssignmentOperatorFormProps;
	assignmentRuleAttributeFormProps: EditHealthAssignmentRuleAttributeFormProps[];
	addAssignmentRuleAttribute: () => void;
	removeAssignmentRuleAttribute: (index: number) => void;
	onFocus?: () => void;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const { data: flagValues } = useFeatureFlags(INTERNATIONAL_CONFIGS_FEATURE_FLAG);

	const isInternationConfigFlagTrue = flagValues?.[INTERNATIONAL_CONFIGS_FEATURE_FLAG] === true;
	const selectOptions = isDependentGroup
		? [{ value: 'country', label: 'Country' }]
		: isSponsorCodePartner
		? [{ value: 'segment', label: 'Sponsor Code' }]
		: isInternationConfigFlagTrue
		? SegmentPartnerSelectOptions
		: [{ value: 'segment', label: 'Segment' }];

	if (isSponsorCodePartner) {
		// we want to clear out all rules beyond the first if this is a demo
		const rangeOfRuleIndicesBeyondFirst = [...Array(assignmentRuleAttributeFormProps.length).keys()].filter(
			index => index !== 0,
		);
		rangeOfRuleIndicesBeyondFirst.forEach(indexToRemove => {
			removeAssignmentRuleAttribute(indexToRemove);
		});
	}

	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>Sponsor Group Calculation</CellTitle>
			<CellSubtitle>
				You can create rulesets using segments from your eligibility file to determine your sponsor group
				members. Each group can have up to 5 rules per group combined using either AND or OR statements.
			</CellSubtitle>
			{assignmentRuleAttributeFormProps.map((formProps, index) => (
				<AssignmentRuleAttributeForm
					key={index}
					formProps={formProps}
					isLast={index === assignmentRuleAttributeFormProps.length - 1}
					hasMultiple={assignmentRuleAttributeFormProps.length > 1}
					operator={assignmentRuleOperatorFormProps.model.operator as AssignmentRuleOperator}
					onClickRemove={() => removeAssignmentRuleAttribute(index)}
					selectOptions={selectOptions}
					isDependentGroup={isDependentGroup}
				/>
			))}
			{!(isSponsorCodePartner || isDependentGroup) &&
				hasValidPermissions('health_sponsorship_assignment_rules', [actions.UPDATE]) && (
					<RuleLogicalOperatorWrapper>
						<RadioGroup
							noMarginBottom
							{...assignmentRuleOperatorFormProps.bindWithErrorProps('operator', 'radio')}
							aria-label="Logical Operator"
							options={[
								{
									label: 'AND',
									value: 'and',
								},
								{
									label: 'OR',
									value: 'or',
								},
							]}
							asCards
							display="flex"
						/>
						<PrimaryButton
							onPress={addAssignmentRuleAttribute}
							isDisabled={assignmentRuleAttributeFormProps.length >= 5}
							data-testid="add-rule-button"
						>
							Add Rule
						</PrimaryButton>
					</RuleLogicalOperatorWrapper>
				)}
			{isSponsorCodePartner && (
				<Text size={0.75} color="gray6">
					Sponsor Code Partners can only have one rule.
				</Text>
			)}
		</Card>
	);
}

export default AssignmentRuleForm;
