import { ChangeEvent, ReactElement } from 'react';

import { Card, FormSelect } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { ClientType as ClientTypeEnum, Partner } from '@/types/store/reducers';
import { ProductSKUType } from '@/utils/SkuEnums';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit?: boolean;
	partner?: Partner;
}

const employerClientTypeOption = {
	label: 'Employer',
	value: ClientTypeEnum.EMPLOYER,
};
const payorClientTypeOption = {
	label: 'Payor',
	value: ClientTypeEnum.PAYOR,
};
const payorBuyUpClientTypeOption = {
	label: 'Payor buy-up',
	value: ClientTypeEnum.PAYOR_BUYUP,
};
const EAPClientTypeOption = {
	label: 'EAP',
	value: ClientTypeEnum.EAP,
};

const getClientTypeOptions = (productSKUType: ProductSKUType): { value: ClientTypeEnum; label: string }[] => {
	if (productSKUType === ProductSKUType.C4B_PAYOR_BASE || productSKUType === ProductSKUType.CH_PAYOR_BASE) {
		return [payorClientTypeOption, EAPClientTypeOption];
	}
	if (productSKUType === ProductSKUType.C4B_PAYOR_BUYUP || productSKUType === ProductSKUType.CH_PAYOR_BUYUP) {
		return [payorBuyUpClientTypeOption, EAPClientTypeOption];
	}
	// only remaining case is that productSKUType is C4B_DIRECT or CH_DIRECT
	return [employerClientTypeOption];
};

export default function ClientType({ formProps }: Props): ReactElement {
	const handleChange = (newClientType: string): void => {
		return formProps.setProperty('clientType', newClientType);
	};

	return (
		<>
			<Card>
				<CellTitle showAdminFlag id="client-type-label">
					Client Type
				</CellTitle>
				<div>
					<FormSelect
						{...formProps.bindWithErrorProps('clientType', 'select')}
						data-testid="select-client-type"
						name="client-type"
						placeholder="Select a client type"
						aria-labelledby="client-type-label"
						onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.value)}
						options={getClientTypeOptions(formProps.model.productSKUType as ProductSKUType)}
					/>
				</div>
			</Card>
		</>
	);
}
