import styled from 'styled-components';

import { maxWidth } from '@calm-web/design-system';

import { PaginationButton, type PaginationButtonProps } from '../Table/styles';

export const NextPrevEllipse = styled(PaginationButton)<PaginationButtonProps>`
	display: inline-block;

	${maxWidth('mobileLarge')} {
		display: none;
	}
`;
