import { FC } from 'react';

import { FormInput } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import { EditHealthEligibilityValidatorFormProps } from '@/hooks/forms/useHealthEligibilityValidatorForm';
import { HealthConfig } from '@/types/health';

import { PhoneOutline, Wrapper, BottomActionBar } from '../styles';
import {
	ViewPort,
	Background,
	WhiteBox,
	Header,
	Image,
	ImageContainer,
	Link,
	SubmitButton,
	Checkmark,
	SkipButton,
	StepIndicatorWrapper,
	PageTitle,
	EligibilityBody,
	EligibilityTitle,
	StepIndicatorBarBlue,
	StepIndicatorBarGrey,
	BreakText,
	RequiredText,
} from './styles';

interface Props {
	healthConfig: HealthConfig;
	formProps: EditHealthEligibilityValidatorFormProps[];
}

const EligibilityValidatorPreview: FC<Props> = ({ healthConfig, formProps }) => {
	const validatorFieldNames = formProps.map(
		(validator, index) =>
			stringFromModelValue(validator.model.validation_display_name)?.toLocaleLowerCase() ||
			`Validation ${index + 1}`,
	);
	const oxfordComma = validatorFieldNames.length > 2 ? ',' : '';
	const validatorNamesList =
		validatorFieldNames.length === 1
			? validatorFieldNames[0]
			: `${validatorFieldNames.slice(0, -1).join(', ')}${oxfordComma} and ${
					validatorFieldNames[validatorFieldNames.length - 1]
			  }`;
	return (
		<Wrapper data-testid="eligibility-validator-preview">
			<Background></Background>
			<PhoneOutline />
			<Header>
				<PageTitle>Registration</PageTitle>
				<StepIndicatorWrapper>
					<StepIndicatorBarBlue />
					<StepIndicatorBarBlue />
					<StepIndicatorBarGrey />
				</StepIndicatorWrapper>
			</Header>
			<ViewPort>
				<WhiteBox>
					<ImageContainer>
						<Image src={healthConfig.light_logo_url ?? '/_n/images/employer-co.png'} alt="Your Logo" />
					</ImageContainer>
					<EligibilityTitle>Check your eligibility</EligibilityTitle>
					{formProps.length > 0 && (
						<EligibilityBody data-testid="eligibility-body-text">
							Please enter your {validatorNamesList} to confirm your eligiblity.
						</EligibilityBody>
					)}
				</WhiteBox>
				{formProps.map((validator, index) => {
					const label = validatorFieldNames[index];
					return (
						<div key={`${stringFromModelValue(validator.model.validation_name) ?? 'validation_'}${index}`}>
							<FormInput
								required={true}
								name={stringFromModelValue(validator.model.validation_name) ?? `validation_${index}`}
								label={label}
								LabelComponent={props => (
									<BreakText id={props.id} htmlFor={props.htmlFor}>
										<>
											{props.children} <RequiredText>(Required)</RequiredText>
										</>
									</BreakText>
								)}
								type="text"
								value={''}
								noValidation={true}
								readOnly={true}
							/>
						</div>
					);
				})}

				<Link onClick={() => {}}>Have a different access code?</Link>
				<Link onClick={() => {}}>Looking for the Calm App?</Link>
			</ViewPort>
			{/* bottom action bar */}
			<BottomActionBar>
				<SkipButton>Skip</SkipButton>
				<SubmitButton>
					<Checkmark />
					Submit
				</SubmitButton>
			</BottomActionBar>
		</Wrapper>
	);
};

export default EligibilityValidatorPreview;
